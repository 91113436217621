import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Details, Blockquote } from "../components/details.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import MoreProjects from "../components/moreProjects.js";
import table from "../images/covid-data/table.png";
import alberta from "../images/covid-data/alberta.png";
import uk from "../images/covid-data/uk.png";
import worldometer from "../images/covid-data/worldometer.png";
import toggle from "../images/covid-data/toggle.png";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { company: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shortTitle
            path
            type
            order
            tags
            company
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1 className="purple">{props.pageContext.frontmatter.shortTitle}</h1>
  <h2>{props.pageContext.frontmatter.title}</h2>
    </div>
    <p>{`As a product design intern at the Ontario Digital Service when the COVID-19 pandemic began, I had the opportunity to design data visualizations to inform the public. Later in the same year, I returned as a full stack engineering intern to continue the development of the data visualization pages. During my internship, I helped to launch three iterations of the site to production which continues receives over 450,000 views per month, and has received international accolades. Visit the live website at `}<a href="https://covid-on-2020.netlify.app" className="link" target="_blank" rel="noreferrer">{`covid-19.ontario.ca/data`}</a>{`.`}</p>
    <Details company={props.pageContext.frontmatter.company} role={props.pageContext.frontmatter.role} timeline={props.pageContext.frontmatter.timeline} skills={props.pageContext.frontmatter.tags} mdxType="Details" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f903e5a68c3622c8abffad7f043598bc/9b379/header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVQ4y4WSy3KbQBBF9f8/lF2W2WQRJ0aKLGHZcjmVCBACJOY9fVKMEPKjXJ6qW0DP5dB9mdkiF+ZrWD0I+TayfozpunkS7p+GmpA/cdVWJiXvY2T1KCxymK+F2QC72wi9sljrknptqVrNvtP0vebUNJwODao7opVG9wqjDca6JK1tgmZrRuCD4JznskKEWkOthCgQtcIfO2zbYtsGXe/xWk9+713qNltx7XAAikgyaGN4fv7DsW2p65pD09J2HWHcv6zBLy+Bbzu8AIvqwJev39hXJV3X4ZzFWQsSL6gzTN50+NHIUQRlHPvmhDYW6wLaC9pFlI1oN8JG4sfA0eD6gnL7HWU83ntCjIQok5Q2hBDeZ/i+w/GLznJsa5xzL16UaT/GOMXz+chyzmc4PohPDBNCqvkoKY6L5+MMN/F1hjHS9yeMM7TmRH3s6FVPqXqOzmK8JU4/6A0wSx2C82EabFjKOlTw6agMow9ZxujwwSWoi1fgEEu+5Qxc5IHlvaaqSqpyRzmqKP5RDip313qxO9eKv+zSdTftL/MT87VntlhbsmXD7e866ddiz895lXSTlUnn5/P9j9viVf3iv8kqsjvFf3il4/RNyMIxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Header image of COVID-19 data microsite",
            "title": "Header image of COVID-19 data microsite",
            "src": "/static/f903e5a68c3622c8abffad7f043598bc/1cfc2/header.png",
            "srcSet": ["/static/f903e5a68c3622c8abffad7f043598bc/3684f/header.png 225w", "/static/f903e5a68c3622c8abffad7f043598bc/fc2a6/header.png 450w", "/static/f903e5a68c3622c8abffad7f043598bc/1cfc2/header.png 900w", "/static/f903e5a68c3622c8abffad7f043598bc/9b379/header.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Context`}</h4>
    <h2>{`Problem`}</h2>
    <p>{`While the main COVID-19 microsite solved many of the issues regarding informing the public with government responses to the pandemic, the lack of comprehendible and official data related to case counts remained.`}</p>
    <p>{`Previously, the Ministry of Health had presented the information in extremely long tables which were overly scientific, and difficult to understand for Ontarians. This problem was reinforced numerous times in the user interviews I helped to conduct for the main microsite. This is summarized by the following:`}<br /><br /></p>
    <div className="row">
  <div className="column">
    <div className="list">
      <h3>1. The tables were very long</h3>
      <h3>2. Difficult to understand the big picture</h3>
      <h3>3. No other official source for understandable infographics</h3>
    </div>
  </div>
  <div className="column column-img">
    <img src={table} alt="Extremely long tables of data" />
    <p className="caption">
      The data tables that were presented were overly long... the one here was
      just a fraction of the full table.
    </p>
  </div>
    </div>
    <h2>{`Breaking down the goal`}</h2>
    <p>{`As informed by our user interviews done for the main COVID-19 website, the main idea Ontarians wanted to understand was if we were flattening the curve of COVID-19 cases. Thus, to guide our solution, we used a how might we statement:`}</p>
    <Blockquote text={"How might we show Ontarians how we, as a province, are flattening or not flattening the curve of the COVID-19 pandemic in a visual manner?"} mdxType="Blockquote" />
    <h2>{`Our approach`}</h2>
    <div className="row">
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">1.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Research</h3>
      <p>User interviews</p>
      <p>Competitive analysis</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">2.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Design</h3>
      <p>Information architecture</p>
      <p>Low-fi designs</p>
      <p>Hi-fi designs</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">3.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Evaluate</h3>
      <p>User testing</p>
      <p>Design iterations</p>
    </div>
  </div>
    </div>
    <div className="row">
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">4.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Development</h3>
      <p>New features</p>
      <p>More graphs</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">5.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Maintenance</h3>
      <p>Bug fixes</p>
      <p>Optimizations</p>
    </div>
  </div>
  <div className="column-3 row"></div>
    </div>
    <p>{`After the initial design prototype, the page was green-lit to proceed towards production. Using our research from user interviews done for the main COVID-19 microsite, we designed mockups for the development team to implement which we constantly iterated upon. This led to the release of the first data visualization page in May 2020.`}</p>
    <p>{`I joined the development team during sprints leading up to the second launch in September and contributed to the maintenance of the site and additional features for the second, third, and fourth iterations. `}<AnchorLink to="/covid-data#design" title="Design" className="link" mdxType="AnchorLink">{`Jump to the final designs I helped create`}</AnchorLink>{` or `}<AnchorLink to="/covid-data#solution" title="Solution" className="link" mdxType="AnchorLink">{`the final coded version I helped launch.`}</AnchorLink></p>
    <h4>{`Research`}</h4>
    <h2>{`Understanding what Ontarians want to see`}</h2>
    <p>{`When conducting user testing for the main Ontario COVID-19 microsite, I also gathered insight into what Ontarians were looking for in a data visualization.`}</p>
    <p>{`Some of the features that the 15+ Ontarians wanted include, but were not limited to:`}</p>
    <ul>
      <li parentName="ul">{`easy to read and understand, preferably in a visual manner`}</li>
      <li parentName="ul">{`easy to access from the trusted Ontario COVID-19 microsite`}</li>
      <li parentName="ul">{`an overall understanding of the "curve"`}</li>
      <li parentName="ul">{`local case statuses`}</li>
      <li parentName="ul">{`understanding of testing and how many people Ontario is testing`}</li>
      <li parentName="ul">{`understanding of hospital and ICU capacity`}</li>
    </ul>
    <h2>{`Analyzing other data visualizations`}</h2>
    <p>{`Ontario was definitely not the first region to be creating data visualizations to inform the public about COVID-19. Thus, we turned to other jurisdictions and websites for inspirations and interesting features we wanted to include in our own site.`}</p>
    <p>{`Three of the main visualizations we analyzed include the `}<a href="https://www.alberta.ca/stats/covid-19-alberta-statistics" className="link" target="_blank" rel="noreferrer">{`Government of Alberta`}</a>{`, `}<a href="https://coronavirus.data.gov.uk" className="link" target="_blank" rel="noreferrer">{`Government of the UK`}</a>{`, and `}<a href="https://www.worldometers.info/coronavirus/country/canada/" className="link" target="_blank" rel="noreferrer">{`worldometers`}</a>{`. At a high level, some of the features we liked and planned to include:`}</p>
    <ul>
      <li parentName="ul">{`"big numbers" above the fold to highlight the important statistics`}</li>
      <li parentName="ul">{`Government of the UK has a jurisdictional breakdown of the cases on both map and table formats`}</li>
      <li parentName="ul">{`the ability to toggle between graph and table formats`}</li>
      <li parentName="ul">{`the ability to download the data`}</li>
      <li parentName="ul">{`simple presentation without superfluous footnotes`}</li>
      <li parentName="ul">{`reinforcement that this is an official source`}</li>
    </ul>
    <br />
    <Accordion allowZeroExpanded mdxType="Accordion">
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">
        See more about the other data visualizations
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <div className="row">
        <div className="column-3 centre-img no-bottom smaller-right">
          <h3>Government of Alberta</h3>
          <img src={alberta} alt="Alberta's data visualizations" className="no-border-img full-img cursor " />
          <p className="caption">
            <a href="https://www.alberta.ca/stats/covid-19-alberta-statistics.htm#new-cases" className="link" target="_blank" rel="noreferrer">
              Alberta's data visualizations
            </a>
          </p>
        </div>
        <div className="column-6">
          <h3>Things we liked</h3>
          <p>Unique visualization layouts that enhance understanding</p>
          <p>Vibrant colours that are easy to differentiate</p>
          <br />
          <h3>Things to avoid</h3>
          <p>Static images</p>
          <p>Confusing toolbar on interactive graphs</p>
          <p>Lack of accesible format</p>
        </div>
      </div>
      <div className="row">
        <div className="column-3 centre-img no-bottom smaller-right">
          <h3>Government of UK</h3>
          <img src={uk} alt="UK's data visualizations" className="no-border-img full-img cursor " />
          <p className="caption">
            <a href="https://coronavirus.data.gov.uk" className="link" target="_blank" rel="noreferrer">
              UK's data visualizations
            </a>
          </p>
        </div>
        <div className="column-6">
          <h3>Things we liked</h3>
          <p>Big numbers</p>
          <p>Brief descriptions to provide context</p>
          <p>Local numbers</p>
          <p>Ability to toggle between graphs and tables</p>
          <p>Ability to download raw data</p>
          <p>Defined colour scheme</p>
          <br />
          <h3>Things to avoid</h3>
          <p>Inefficient usage of space</p>
          <p>Long page</p>
        </div>
      </div>
      <div className="row">
        <div className="column-3 centre-img no-bottom smaller-right">
          <h3>worldometer</h3>
          <img src={worldometer} alt="worldometer's data visualizations" className="no-border-img full-img cursor " />
          <p className="caption">
            <a href="https://www.worldometers.info/coronavirus/country/canada/" className="link" target="_blank" rel="noreferrer">
              worldometer's data visualizations
            </a>
          </p>
        </div>
        <div className="column-6">
          <h3>Things we liked</h3>
          <p>Big numbers</p>
          <p>Simple graphs</p>
          <p>Ability to toggle between linear and logarithmic modes</p>
          <p>Clean tooltip</p>
          <p>Comparative graphs</p>
          <br />
          <h3>Things to avoid</h3>
          <p>Inefficient usage of space</p>
          <p>Not enough context per graph</p>
          <p>Unsure of the official source</p>
        </div>
      </div>
    </AccordionItemPanel>
  </AccordionItem>
    </Accordion>
    <h4>{`Design`}</h4>
    <h2>{`Brainstorming ideas`}</h2>
    <p>{`When we initially pitched the concept of the COVID-19 microsite, we also included the idea of a data visualization page. This concept can be seen below and was heavily based off sources like Alberta's COVID-19 status page and worldometers which were retrofitted with `}<a href="https://designsystem.ontario.ca" className="link" target="_blank" rel="noreferrer">{`Ontario's design system`}</a>{` patterns.`}<br /><br /></p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FE0r9HDjQWjaTA4r3aoqdpP%2FCOVID-19-data-viz-Initial-prototype%3Fnode-id%3D1%253A19%26viewport%3D543%252C703%252C0.16830630600452423%26scaling%3Dscale-down-width" allowFullScreen></iframe>
    <br />
    <br />
    <p>{`We used this original concept as a jumping off point for our ideation. A great way to brainstorm is through the diverge-converge method. Individually, we sketched our ideas for what data we wanted to show and the best way to represent it. Then, we came together as a group to discuss and consolidate some of our ideas.`}</p>
    <p>{`We also compiled a moodboard of various data visualizations we liked. This included sources from Ontario's static health updates, Alberta's data visualizations, and `}<a href="https://russell-pollari.github.io/ontario-covid19/" className="link" target="_blank" rel="noreferrer">{`Russell Pollari's dashboard`}</a>{`. All of these initial ideas can be seen in the Figma file below!`}<br /><br /></p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FSEReiybErhx9nRxHksi1qJ%2FCOVID-19-data-viz-%25E2%2580%2593-Ideation%3Fnode-id%3D0%253A1" allowFullScreen></iframe>
    <h2>{`Creating an accessible interface`}</h2>
    <p>{`One of the main features that we ensured to include in the data visualizations was the toggle between graph and table format. This is because as a government entity, we needed to ensure that all Ontarians are able to access this information whether it is visually or through the use of a screenreader. Many sites display great data visualizations, but sometimes forget to also include tables for those who require a table format.`}</p>
    <div className="row">
  <div className="column">
    <p>
      The UK government had an interesting approach to this. They used radio
      buttons to control what mode they are currently in. This switch would
      control the content on the entire page, switching the data from a graph
      format to table format or vice versa.
    </p>
    <p>
      We looked at a number of ways we could implement this idea. This includes
      the use of radio buttons like the UK government, tabs, button groups, and
      linked text.
    </p>
  </div>
  <div className="column column-img">
    <img src={toggle} alt="UK's toggle" />
    <p className="caption">
      The UK government use radio buttons to toggle between graphs and tables.
    </p>
  </div>
    </div>
    <p>{`Initially, we used linked text. Then we swiched to the use of a button group. This was to help differentiate the function from links we used for definitions in the description. We chose button groups over tabs because we used tabs to create a "sheet" that flips between daily or cumulative views.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a99661c6fc3762fba4a533ca3dfd8e04/26a94/toggle2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.55555555555556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABi0lEQVQoz12Su27bQBBF+f8fkBRJihRxkS5N3AWBC8dFCiuiSPElypKiF8WHKXP51JxguUJgeIGzs3MxWOzOXMsJwQllxPYHpvNujK91AziBMAtMPvV6bK+/5si1Tiwt2D54S/h26/D+4y2fPv/g7teRWQh/5uYSA6M2j+HL1wfeffjOz/uD1sTUIVawhPipJFjkeGGK7W4JopRjKqQFHFPwYnAj8KIa290xD05s9y2bnWKf9CQZkmSCtxCxlhuhLE7sd2vyLCHLEs5lQdsq2qZi6DvcQHH/O2f994VzmVK9PNM2ildL9BY+XcSKVgNJXrI5FpxVR9f1tG1HXTc0TUvdtHiLnkenZ71tyIqSLC85JidOaU6S5hzyWoqqJ1xedA8HZr5i6itsr8IJ1Ih7ZczDyziUeFPjrXbMNwXhvho5FDVJ2Uk3CJF+oW6ybraZqjm/RU/XDmEVb0kmN2zXMdmzQtX6Nz1d24v+lR8PYuni/3YI5I1VDFdrjFaZOIqJWzNxDI8G0dH2L/IPneFPvMLbMhIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "We iterated on the toggle format",
            "title": "We iterated on the toggle format",
            "src": "/static/a99661c6fc3762fba4a533ca3dfd8e04/1cfc2/toggle2.png",
            "srcSet": ["/static/a99661c6fc3762fba4a533ca3dfd8e04/3684f/toggle2.png 225w", "/static/a99661c6fc3762fba4a533ca3dfd8e04/fc2a6/toggle2.png 450w", "/static/a99661c6fc3762fba4a533ca3dfd8e04/1cfc2/toggle2.png 900w", "/static/a99661c6fc3762fba4a533ca3dfd8e04/26a94/toggle2.png 1102w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  Before we used linked text, but then switched to button groups and tabs.
    </p>
    <p>{`Another issue that was brought to my attention was the use of colours on data visualizations. I have the ability to see colour, so it did not occur to me that the colours we were choosing would be difficult to differentiate for colourblind users. This scenario arose during a user testing session when I interviewed a user who is colourblind brought to our attention how difficult it was for them to differentiate the items being compared since we relied on colour to separate items on the same graph.`}</p>
    <p>{`Having never worked with data visualizations before, I found resources to help me learn how to present data in the best way for many to understand. I used `}<a href="https://fossheim.io/writing/posts/accessible-dataviz-design/" className="link" target="_blank" rel="noreferrer">{`Sarah Fossheim's introduction article`}</a>{` and `}<a href="https://keen.io/blog/accessibility-in-data-vis/" className="link" target="_blank" rel="noreferrer">{`Keen.io’s guide`}</a>{`.`}</p>
    <p>{`The two main things I learned was to not rely on colour as a way to differentiate between items on a graph and to provide labels where possible, as well as to limit the items being presented to a minimum.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bfcd2c5391b3c62cf4d70a42078f612e/5bf79/colourblind.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.44444444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACAElEQVQoz22SSWvbUBSF/VubH5D8hmbdTcmmm9IUCl3U25IW2oUpZHDSxnEsS7Y8xBosD2iwPMiWdL/ynpOUQB8cLu+9cw/nDhXDBsMW2j3oOf/QfnxX/63uPu7vgtmH7gP0XHSeYYs88qTStEqsAZz96PPm7Rfevf/O52qdW6Og2Xkp1rQEowc/f/ma++FTjd+tLfcd5L6jeVKxHwR/WmLaIedXHeo3fa7/DBh5W8bTvZASVRh6Jd6kwLIjzbm8tukNl4ynpfQd4V45HE+FYDzCcR4Ixj6z2YQkiVgsIkC0e+VKRT+IGA66hOGcNE00Lwh88t1aZiGqAqk4fo7nOfjjgDRNEeH5lCXcmRvOr+fcNFPmYcYiiVkuV6Tpks1mw26XUxQ7CWalapFU/ImoB7IsexYSEZIkIYoSzL5wa2TcmTlJWpBla9brDdvtlqIonlNeCD6dKIppt9vUajWq1SoXl1cMPFANV9OchxvyfEtZijagnKqcOA7FnxR6OFrw29lXTk5OOD5+zeHhIUdHRxwcvOL09CPuBBpmTsuGKN6SxCFBEOB5Hq7rMBqNmEx88aePgm5Q0mg0uLisYxgmbdPCsrqYpoXrBnSGe4dqyvGi1H1bLtes1htd+mqVqfK1Q12yYZd6kQfuHn0FZx/V8j7tYUvvYo7Z+y/EsAtaXeQvbALdsu8eW9AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Changing colours to have contrast when black and white",
            "title": "Changing colours to have contrast when black and white",
            "src": "/static/bfcd2c5391b3c62cf4d70a42078f612e/1cfc2/colourblind.png",
            "srcSet": ["/static/bfcd2c5391b3c62cf4d70a42078f612e/3684f/colourblind.png 225w", "/static/bfcd2c5391b3c62cf4d70a42078f612e/fc2a6/colourblind.png 450w", "/static/bfcd2c5391b3c62cf4d70a42078f612e/1cfc2/colourblind.png 900w", "/static/bfcd2c5391b3c62cf4d70a42078f612e/5bf79/colourblind.png 966w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  We worked on adding contrast to the colours so its differentiable when black
  and white.
    </p>
    <h2>{`Continuing to iterate`}</h2>
    <p>{`As we were designing the interface, we conducted numerous rounds of user testing. This also gave the government time to collect more data to display to the public.`}</p>
    <div id="design"></div>
    <p>{`At the end of my internship, the data visualizations were not yet launched as development was still occurring. Below is a sample of the final designs I worked on before development finished.`}</p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FQQv2sLouKSPVJFj3EwJozb%2FCOVID-19-data-viz-%25E2%2580%2593-end-of-design%3Fnode-id%3D1%253A3%26viewport%3D779%252C1325%252C0.41164255142211914%26scaling%3Dscale-down-width" allowFullScreen></iframe>
    <p className="caption">
  Design for version 1 of the data visualizations which launched in May 2020.
    </p>
    <h4>{`Development`}</h4>
    <h2>{`Understanding the structure of the application`}</h2>
    <p>{`I stepped into the development role in my second internship with the Ontario Digital Service in September 2020, and it was like coming back to an old friend. Having context on the COVID-19 data visualizations as a product designer helped to minimize onboarding, and I could jump right in as a developer.`}</p>
    <p>{`One of the first things I did was understand how the overall app works. Essentially, when a user requests `}<a href="https://covid-on-2020.netlify.app" className="link" target="_blank" rel="noreferrer">{`covid-19.ontario.ca/data`}</a>{`, it gets the site information from Drupal, an open source CMS that the government of Ontaria uses widely for its static websites. This page in Drupal goes off to request the actual data visualizations on AWS cloudfront via a custom script written in the Drupal page's HTML and shows the data visualizations as if they were done inline.`}</p>
    <p>{`The page on AWS cloudfront is served by an S3 bucket. This is kept current by a build process triggered in our Gitlab CI/CD processes. In this process, it pings the `}<a href="https://data.ontario.ca/en/group/2019-novel-coronavirus" className="link" target="_blank" rel="noreferrer">{`Ontario data catalogue`}</a>{` for the latest COVID-19 data, and processes the React app. The app is built once a day to gather the latest data.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/356d3b44d6159d49836724623f63c6ae/95e27/structure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.44444444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWklEQVQoz5WSC2+cMBCE+f8/r+QqtWDIPdLcAeZlzMMGf5F9OZWkUdWONPKsdllGY0fJs+P0y3F850dNqE9f6MfM+RXOV8KZnx3RrXKA5wOf9Y5uX99hzMKgFNtmGSdHdK3uTRfmH9qxbdsf6/fwM33fU5YlT08Huq5hnCAqJNSyoihK2rZlGAa6rmOcJsw8MV9STN+wGEtVVszzHPhyeaFtmrAwjmPyTDDojegmoW0kspJhoZQSkQq6rkd1LfXPGF2XdL0izzKKoqCua5IkoaoqtNYopTBmRo/vGVozsyyGZVmw1gaX3sW6rtyKAmNMiMAv8D2PcRyDAZGmZFnGqAfGGaJr6f6a1e/MPubn4X8uhCBNU7RW9wxvu0v5/MFXel97198PB+JvMYPqme4O/W1uoencPzLMuuDQx+BznSeN9s/mR7Yijob0aP+b4mjJL47niyM7rSS55Q3ZaAMQrSarlQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Structure of data application",
            "title": "Structure of data application",
            "src": "/static/356d3b44d6159d49836724623f63c6ae/1cfc2/structure.png",
            "srcSet": ["/static/356d3b44d6159d49836724623f63c6ae/3684f/structure.png 225w", "/static/356d3b44d6159d49836724623f63c6ae/fc2a6/structure.png 450w", "/static/356d3b44d6159d49836724623f63c6ae/1cfc2/structure.png 900w", "/static/356d3b44d6159d49836724623f63c6ae/95e27/structure.png 1199w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">A high level overview of the app's structure.</p>
    <p>{`By understanding the overall app, I was able to contribute to all parts of the app's stack. During my term, I learned a lot about the inner workings of browsers, how a CMS is helpful for large teams, debugging and optimizing build processes, data integrity, refactoring styles within an app, as well as structuring large applications in React.`}</p>
    <h2>{`Creating new features and graphs`}</h2>
    <p>{`Before I got there, the team was in limbo waiting for executive approvals to continue development for a version 2 launch. However, upon joining the team, I was put to work almost immediately as we just got the go-ahead and a deadline for putting version 2 into production!`}</p>
    <p>{`Thus, I had to quickly ramp up on the stack. Some of the libraries we used include:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://apexcharts.com" className="link" target="_blank" rel="noreferrer">
  ApexCharts
        </a> for the graphs
      </li>
      <li parentName="ul">
        <a href="https://ant.design" className="link" target="_blank" rel="noreferrer">
  Antd
        </a> for tables, buttons, and tables
      </li>
      <li parentName="ul">
        <a href="https://react-leaflet.js.org" className="link" target="_blank" rel="noreferrer">
  React Leaflet
        </a> for the maps
      </li>
    </ul>
    <br />
    <p>{`For versions 2, 3, and 4, I worked on a lot of new features which include:`}</p>
    <ul>
      <li parentName="ul">{`Regional maps with toggable scales`}</li>
      <li parentName="ul">{`Clusters on regional maps`}</li>
      <li parentName="ul">{`Additional bar graphs and tables with numerous data layers`}</li>
      <li parentName="ul">{`Searching for a region and setting the data to a graph`}</li>
      <li parentName="ul">{`Graphs with checkboxes to set data to a graph`}</li>
      <li parentName="ul">{`Highlighting data anomalies with a custom tooltip`}</li>
    </ul>
    <br />
    <p>{`I also got to work on creating data schemas for various COVID-19 data sets. The collection of COVID-19 data sets can be seen `}<a href="https://data.ontario.ca/en/group/2019-novel-coronavirus" className="link" target="_blank" rel="noreferrer">{`here`}</a>{`.`}</p>
    <p>{`The final outcome can be seen `}<AnchorLink to="/covid-data#solution" title="Solution" className="link" mdxType="AnchorLink">{`below`}</AnchorLink>{`. During this process, I learned a lot about React hooks, state management, debugging, and about the libraries (React Leaflet and ApexCharts).`}</p>
    <p>{`In addition, in around October, Ontario was seeing an increase in COVID-19 cases. Ontario helped create was the COVID-19 alert app that helps with contact tracing, and government officials were promoting it heavily to help curb the spike in cases.`}</p>
    <p>{`I was put in charge of developing a small data visualization to display statistics related to the app's downloads and usage to alert others. While creating this, I optimized the build bundle, and refactored the styles to reduce build times. It was a really good experience, as I was able to own the entire end-to-end process from setting up the repository and app structure, collaborating with the design team and product manager closely, and really solidify my fundamentals in building new graphs with our app's components.`}</p>
    <p>{`Below is the final iteration of the COVID-19 alert app statistics gathered as of January 16th, 2021 and is live at `}<a href="https://covid-alert-on-2020.netlify.app" className="link" target="_blank" rel="noreferrer">{`covid-19.ontario.ca/covidalert`}</a>{`.`}<br /><br /></p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="600" src="https://covid-alert-on-2020.netlify.app"></iframe>
    <p className="caption">COVID-19 alert app data visualizations</p>
    <h2>{`Optimizing the site`}</h2>
    <p>{`One of the common issues we ran into as a team was the build process. Sometimes, the build would fail halfway through, or the build would take close to 10 minutes to complete! This was not sustainable and caused us to manually check each day to ensure it was successful.`}</p>
    <p>{`To help mitigate this, I worked with the DevOps team to optimize the pipeline, automating the build proccesses to pass 96% of the time. I also worked on refactoring parts of the app to reduce the size of files, and ultimately reducing the build time by 52%. This also helped to decrease overall load times of the page by 34%.`}</p>
    <p>{`Another major issue I helped to resolve was the overwhelming global styles that come with using the Antd components. Antd uses global styles, and while they did not overwrite completely in our application itself, it overwrote the styles the are associated with the Drupal CMS. To help mitigate this, I worked on refactoring and scoping the styles manually, also removing over 2,000 lines of duplicated CSS/SCSS code in the process.`}</p>
    <div id="solution"></div>
    <h4>{`Solution`}</h4>
    <h2>{`Final site`}</h2>
    <p>{`At the end of my second internship as a developer in December 2020, we were about to launch the fifth iteration of data visualizations. Below is the final iteration that I helped launch at the end of my second internship and displays data gathered as of January 15th, 2021. You can see the live site at `}<a href="https://covid-on-2020.netlify.app" className="link" target="_blank" rel="noreferrer">{`covid-19.ontario.ca/data`}</a>{`.`}<br /><br /></p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="600" src="https://covid-on-2020.netlify.app"></iframe>
    <p className="caption">Main data visualization page</p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="600" src="https://covid-on-2020.netlify.app/?page=LTC2"></iframe>
    <p className="caption">
  Data visualizations depicting long term care home data
    </p>
    <p>{`*`}{`Please note that some of the links may not work since they are connected to elements that would typically be included in the Drupal CMS body rather than the React app.`}</p>
    <h2>{`Results`}</h2>
    <p>{`Shortly after my product design internship at the Ontario Digital Service, the initial data visualization page got launched. Then as a developer intern, I got to be an integral part in launching the second, third, and fourth iterations of the data visualization page.`}</p>
    <p>{`Since its launch, the site has received:`}</p>
    <Blockquote text={"Over 20 million views since its launch"} mdxType="Blockquote" />
    <Blockquote text={"Numerous international accolades from other governments"} mdxType="Blockquote" />
    <p>{`Overall, I believe the COVID-19 data visualizations were successful and helpful to all Ontarians in providing up to date and reliable information. My mom is one of the site's frequent visitors and she loves it!`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`Also, after the launch of the second iteration, we opened a feedback form to gain insight from Ontarians. From it, we learned that the site was really helpful in giving people an overview of COVID-19 in Ontario. We also recieved tons of amazing suggestions for improvements we could make. The team will continue to work on those suggestions.`}</p>
    <p>{`On the product roadmap, there are plans for creating new visualizations with different data for different ministries. This includes the eventual release of visualizations for schools and childcare centres, as well as visualizations for the Ministy of the Solicitor General.`}</p>
    <h2>{`Reflecting back`}</h2>
    <p>{`I learned a ton from working on this product. I learned everything from user research to frontend development to maintaining build processes. Not only did I get to participate in its conception, but I also got to work on new features and learn how to maintain a product after its launch.`}</p>
    <p>{`I am proud of the overall impact I was able to make through working on Ontario's COVID-19 data visualizations. I look forward to continue working on impactful products in the future! I also worked with an amazing, supportive team! The product manager, Emily, of my team said:`}</p>
    <Blockquote text={'"Emily was an indispensable part of the covid-19.ontario.ca team. She showed incredible initiative and dedication during her time with us, consistently going above and beyond to ensure we delivered an amazing product for Ontarians. Her ability to debug issues and jump into unfamiliar codebases to build covid19.ontario.ca/data allowed us to make key feature enhancements to the product."'} mdxType="Blockquote" />
– Emily Tamfo, Product Manager of <a href="https://covid-19.ontario.ca/" className="link" target="_blank" rel="noreferrer">
  covid-19.ontario.ca
    </a>
    <br />
    <h4>{`More case studies`}</h4>
    <MoreProjects projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="MoreProjects" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      